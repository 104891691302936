import {JsonAdapter} from "../../shared/helpers/JsonAdapter";
import {NavigationItem} from "../../shared/models/NavigationItem";

export class TenantConfig {
  tenant: string = "";
  hosts: string[] = [];
  baseUrl: string = "";
  apiBaseUrl: string = "";
  apiV1BaseUrl: string = "";
  logo: string = "";
  organisationHub: {AZMenuItems: string} = {AZMenuItems: ""};
  signIn: {layout: string, fullscreen: boolean} = {layout: "cover", fullscreen: true};
  signUp: {enabled: boolean} = {enabled: true};
  topNavigation: {config: any, items: NavigationItem[]} = {config: {}, items: []};

  static create(object:any): TenantConfig {
    const item = JsonAdapter.fill(new TenantConfig(), object);

    if(object.hosts) {
      item.hosts = object.hosts;
    }

    if(object.organisationHub) {
      item.organisationHub = object.organisationHub;
    }

    if(object.topNavigation) {
      item.topNavigation = object.topNavigation;
    }

    return item;
  }
}
