import {Tenant} from "../services/tenant.service";
import {TenantConfig} from "./TenantConfig";
import {NavigationItem} from "../../shared/models/NavigationItem";

export class TenantConfigList {

  static tenantConfigs: {
    [key: string]: {
      environments: {
        [key: string]: TenantConfig
      }
    }
  } = {};

  static defaultTenantConfig = TenantConfig.create({
    tenant: Tenant.TALENTVIEW,
    baseUrl: '/',
    logo: '/assets/images/logos/talentview_academy_logo.svg',
    signIn: {
      layout: 'cover',
      fullscreen: true
    },
    signUp: {
      enabled: true
    },
    organisationHub: {
      AZMenuItems: 'sponsor_partner,company,supporting_organisation,university,fe_college'
    },
    topNavigation: {
      config: {},
      items: [
        NavigationItem.create({
          routerLink: '/account/sign-in',
          title: 'Log In',
          visibleIfGuest: true
        }),
        NavigationItem.create({
          routerLink: '/account/sign-up',
          title: 'Sign Up',
          visibleIfGuest: true
        }),
        NavigationItem.create({
          routerLink: '/dashboard',
          title: 'My Dashboard',
          visibleIfMember: true
        })
      ]
    }
  });

  static get() {
    // Default tenant (Talentview)
    this.tenantConfigs[Tenant.TALENTVIEW] = {
      environments: {
        production: {
          ...JSON.parse(JSON.stringify(this.defaultTenantConfig)), ...{
            signUp: {
              enabled: false
            }
          }
        } as TenantConfig
      }
    }

    // Construction tenant
    this.tenantConfigs[Tenant.CONSTRUCTION] = {
      environments: {
        production: TenantConfig.create({
          tenant: Tenant.CONSTRUCTION,
          logo: '/assets/tenants/construction/construction-logo.svg',
          organisationHub: {
            AZMenuItems: 'construction_sector_company,construction_supporting_organisation,university,fe_college'
          }
        })
      }
    };

    // Deep copy of default tenant config as DNCP tenant
    this.tenantConfigs[Tenant.DNCP] = {
      environments: {
        production: {
          ...JSON.parse(JSON.stringify(this.defaultTenantConfig)), ...{
            tenant: Tenant.DNCP,
            hosts: [
              'dev.destinationnuclearcareersportal.co.uk',
              'dev.dncp.test'
            ],
            baseUrl: '/',
            apiBaseUrl: 'https://api.devel.destinationnuclearcareersportal.co.uk/api/v1/',
            apiV1BaseUrl: 'https://api.devel.destinationnuclearcareersportal.co.uk/api/v1/',
            logo: '/assets/tenants/destination-nuclear/destination_nuclear_logo_new.png',
            signIn: {
              layout: 'centered',
              fullscreen: false
            },
          }
        } as TenantConfig
      }
    };

    this.tenantConfigs[Tenant.DNCP].environments['production'].topNavigation.items = [
      ...[NavigationItem.create({
        routerLink: '/jobs',
        title: 'Browse Opportunities'
      }),
        NavigationItem.create({
          routerLink: '/what-we-offer',
          title: 'What We Offer'
        }),
        NavigationItem.create({
          routerLink: '/organisation-hub',
          title: 'Organisations Hub'
        }),
        NavigationItem.create({
          routerLink: '/news',
          title: 'News'
        })],
      ...this.tenantConfigs[Tenant.DNCP].environments['production'].topNavigation.items
    ]

    // DNCP phase2 tenant environment config
    this.tenantConfigs[Tenant.DNCP].environments['phase2'] = {
      ...JSON.parse(JSON.stringify(this.tenantConfigs[Tenant.DNCP].environments['production'])), ...{
        //apiBaseUrl: 'https://api.phase2.destinationnuclearcareersportal.co.uk/api/v1/',
        //apiV1BaseUrl: 'https://api.phase2.destinationnuclearcareersportal.co.uk/api/v1/',
        apiBaseUrl: 'https://api.devel.destinationnuclearcareersportal.co.uk/api/v1/',
        apiV1BaseUrl: 'https://api.devel.destinationnuclearcareersportal.co.uk/api/v1/',
        hosts: [
          'phase2.destinationnuclearcareersportal.co.uk',
          'phase2.dncp.test'
        ],
      }
    }

    return this.tenantConfigs;
  }
}
